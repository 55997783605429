/* Footer */
.footer-container {
  color: var(--ground_color);
  background-color: #333333;
  font-size: 15px;
}

.footer-container a {
  color: var(--ground_color);
  text-decoration: none;
}

.footer-body {
  max-width: 1240px;
  padding: 20px 0;
  margin: 0 auto;
}

.footer-info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.footer-info-article {
  width: 250px;
  margin: 30px;
}

.footer-call:hover {
  color: var(--layout-color);
  transition: color 0.3s ease-in-out;
}

.footer-contact-row {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
  line-height: 25px;
}

.footer-copyright {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 14px;
  padding-left: 30px;
  font-weight: 500;
  color: var(--headers-color);
}

.footer-copyright-text {
  margin-right: 10px;
}

.footer-copyright-devider {
  margin: 0 10px;
}

.footer-location-icon {
  font-size: 25px !important;
  margin-right: 10px !important;
}

.footer-contact-icon {
  margin-top: 5px;
  font-size: 15px;
  margin-right: 15px;
}

.footer-article-header {
  font-size: 20px;
  margin-bottom: 20px;
}

.footer-article-list {
  margin-left: 15px;
}

.footer-article-list li {
  margin-bottom: 10px;
}

.footer-article-list a:hover {
  color: var(--layout-color);
  transition: color 0.3s ease-in-out;
}

.footer-devider {
  background-color: var(--headers-color);
  height: 1px;
  width: calc(100% - 60px);
  margin: 0 auto;
}

.footer-subscribe {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-subscribe-box {
  margin: 30px 40px;
}

.footer-alert {
  margin-top: 10px;
  color: var(--error-color);
  font-size: 14px;
  height: 20px;
}

.footer-form {
  display: flex;
}

.footer-input {
  border: 1px solid #dfdfdf;
  border-width: 2px;
  border-right: 0;
  padding: 10px 30px 10px 15px;
  color: var(--primary-color);
  font-size: 16px;
}

.footer-input:focus {
  outline: none;
}

.footer-input-error {
  border-color: var(--error-color);
}

.footer-form-btn {
  cursor: pointer;
  background-color: #333333;
  border: 2px solid var(--headers-color);
  border-left: 2px solid #333333;
  padding: 10px 20px;
  color: var(--ground_color);
  font-size: 14px;
  font-weight: 600;
}

.footer-form-btn:hover {
  background-color: var(--ground_color);
  color: var(--headers-color);
  border-left: 2px solid var(--headers-color);
}

.footer-form-btn:disabled {
  cursor: not-allowed;
  background-color: var(--disable-color);
}

@media (min-width: 1024px) {
  .footer-input {
    min-width: 480px;
  }

  .footer-form-btn {
    padding: 14px 38px;
    font-size: 16px;
  }
}
