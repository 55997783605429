@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

:root {
  --ground_color: white;
  --headers-color: black;
  --primary-color: #666;
  --secondary-color: #f26200;
  --secondary-hover: #f86f14;
  --layout-color: #00816d;
  --error-color: #ff3333;
  --success-color: #35a835;
  --light-ground: #f2f2f2;
  --disable-color: #cfcece;
  --disable-hover: #dadada;
  --btn-blue: rgba(0, 87, 248, 0.5);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Rubik, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  background-color: var(--ground_color);
  color: var(--primary-color);
  overflow-y: auto;
  min-height: calc(100vh - 69px);
}

input[type='file'] {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.grecaptcha-badge {
  visibility: hidden;
}

.secondary-text {
  color: var(--secondary-color);
}

.text-bold {
  font-weight: 600;
}

/* Home */
.home-container {
  margin-top: 69px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
  max-width: 1200px;
}

.home-logo {
  width: 100%;
  height: auto;
}

.home-description {
  margin-top: 50px;
  margin-bottom: 20px;
}

.home-description-row {
  display: flex;
  align-items: center;
  margin-top: 30px;
  font-size: 18px;
}

.home-description-icon {
  width: 50px;
  height: auto;
  margin-right: 30px;
}

.home-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home-actions-box {
  width: 380px;
  margin: 20px;
  background-image: url('../img/box_layout.png');
  background-position: center 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.home-actions-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
  margin: 15px;
  padding: 20px;
  background-color: var(--ground_color);
  text-align: center;
}

.home-actions-btn {
  font-weight: 600;
  text-decoration: none;
  margin: 30px 0;
  background-color: var(--secondary-color);
  padding: 10px;
  color: var(--ground_color);
  box-shadow: 2px 2px 4px var(--disable-color);
}

.home-actions-btn:hover {
  background-color: var(--secondary-hover);
  box-shadow: 3px 3px 6px var(--disable-color);
}

.home-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  width: 100%;
  margin: 40px 0;
  background-image: url('../img/gritr_layout.png');
  background-position: center 0;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--ground_color);
  font-size: 30px;
  font-weight: 600;
}

.home-info {
  display: grid;
}

.home-info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 200px;
  padding: 10px 30px;
  font-size: 18px;
}

.home-info-icon {
  margin-bottom: 30px;
  height: 50px;
  width: auto;
}

.home-black-text {
  color: black;
  margin-left: 20px;
}

.home-label-gritr {
  display: flex;
  flex-wrap: wrap;
}

.home-description2 {
  margin-top: 0;
}

/* Alert */

.alert-container {
  display: grid;
  grid-gap: 10px;
  top: 20px;
  position: fixed;
  right: 20px;
  z-index: 30;
}

.allert-message {
  display: flex;
  flex-direction: column;
  background-color: var(--light-ground);
  padding: 10px 20px;
  width: 220px;
  min-height: 60px;
  border-radius: 10px;
  color: black;
  font-size: 14px;
}

.allert-message-header {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
}

.allert-message-error {
  background-color: #f8a5a5;
}

.allert-message-success {
  background-color: #bef9be;
}

.allert-message-success-icon {
  color: var(--success-color);
  margin-right: 10px;
  font-size: 25px;
}

.allert-message-error-icon {
  color: var(--error-color);
  margin-right: 10px;
  font-size: 25px;
}

/* Icons */
.required-icon {
  font-weight: 600;
  color: var(--error-color);
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.info-icon-container {
  position: relative;
}

.info-icon {
  position: relative;
  cursor: pointer;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  padding: 1px 6px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 50%;
  background-color: var(--light-ground);
  color: var(--secondary-color);
}

.info-icon:hover + .info-icon-text {
  opacity: 1;
  z-index: 1;
}

.info-icon-text {
  position: absolute;
  top: 15px;
  left: 0;
  opacity: 0;
  padding: 10px;
  background-color: var(--disable-color);
  color: var(--headers-color);
  font-size: 13px;
  box-shadow: 2px 2px 4px var(--disable-color);
  transition: opacity 0.3s ease-in-out;
  width: 200px;
  border-radius: 5px;
}

/* Checkbox */
.round-checkbox-container {
  display: flex;
  align-items: center;
  width: 40px;
  height: 30px;
}
.round-checkbox {
  position: relative;
}
.round-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  width: 14px;
}

.round-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 3px;
  left: 2px;
  opacity: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-45deg);
  width: 6px;
  transition: opacity 0.2s ease-in-out;
}

.round-checkbox input[type='checkbox'] {
  visibility: hidden;
}

.round-checkbox input[type='checkbox']:checked + label {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.round-checkbox input[type='checkbox']:checked + label:after {
  opacity: 1;
}

/* Breadcrumbs */

.breadcrumbs-link {
  color: var(--layout-color);
  text-decoration: none;
}

/* Skeleton */
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #f0ecf0;
  border-radius: 5px;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 5s infinite;
  -webkit-animation: shimmer 5s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@media (min-width: 768px) {
  .home-actions-box {
    height: 300px;
    margin: 30px;
  }

  .home-info {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .home-info {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .home-label {
    height: 65px;
  }

  .home-label-gritr {
    height: 100px;
    font-size: 35px;
    margin: 60px 0;
  }
}
