.modal {
  display: none; /*Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 12; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  padding-bottom: 100px;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-container {
  position: relative;
  background-color: #fefefe;
  color: var(--dark-color);
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  max-width: 950px;
  height: 75vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modal-header {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  border-bottom: 1px solid var(--primary-color);
}

.modal-title {
  text-align: center;
  flex-grow: 1;
  font-size: 20px;
  font-weight: 600;
  color: var(--secondary-color);
}

.modal-body {
  display: grid;
  padding: 10px;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 90%;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.modal-body-name {
  margin-top: 5px;
  margin-bottom: 10px;
}

.modal-body-header {
  display: flex;
  flex-wrap: wrap;
}

.modal-body-header-param {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.modal-body-header-name {
  font-weight: 600;
  margin-right: 10px;
}

.modal-body-header-value {
  padding: 5px 10px;
  border: 1px solid var(--disable-color);
  border-radius: 3px;
}

.modal-body-param {
  margin: 20px 10px;
  display: flex;
  flex-wrap: wrap;
}

.modal-body-message {
  width: fit-content;
  max-height: 400px;
  max-width: 700px;
  padding: 5px 10px;
  border: 1px solid var(--disable-color);
  border-radius: 3px;
  overflow-y: auto;
  word-wrap: break-word;
  word-break: break-all;
}

.modal-actions-list {
  margin: 20px auto;
}

/* Confirmation Modal */
.modal-container-confirm {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fefefe;
  color: var(--primary-color);
  margin: auto;
  padding: 15px;
  border-radius: 5px;
  max-width: 550px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modal-confirm-message {
  font-size: 18px;
  margin: 25px 10px;
  text-align: center;
}

.modal-confirm-buttons {
  display: flex;
  align-items: center;
}

.modal-btn {
  margin: 10px;
  padding: 10px 20px;
  box-shadow: 2px 2px 5px var(--disable-color);
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  background-color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  color: var(--ground_color);
  justify-self: center;
}

.modal-btn:hover {
  box-shadow: 3px 3px 7px var(--disable-color);
}

.modal-cancel-btn {
  color: var(--primary-color);
  background-color: var(--ground_color);
  border: 1px solid var(--disable-color);
}

.modal-cancel-btn:hover {
  color: var(--disable-color);
  border: 1px solid var(--primary-color);
}

.modal-update-btn:hover {
  background-color: var(--secondary-color);
}

.modal-delete-btn {
  background-color: var(--error-color);
}

.modal-delete-btn:hover {
  background-color: var(--error-color-hover);
}

.modal-close-icon {
  font-size: 25px;
  cursor: pointer;
  align-self: flex-end;
}

.modal-confirm-icon {
  font-size: 55px;
  color: var(--success-color);
}

/* Modal Info styles */
.modal-body-center {
  margin-left: auto;
  margin-right: auto;
}

.modal-list {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 10px;
}

.modal-list li {
  list-style: none;
  margin-bottom: 20px;
}

.modal-list li::before {
  content: '';
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-left: -20px;
  margin-right: 12px;
  background-color: var(--secondary-color);
}

.modal-info-img {
  width: 100%;
  height: auto;
}

.modal-markets-active {
  color: var(--secondary-color);
}

.modal-markets-active span:hover {
  color: var(--secondary-color) !important;
}

.modal-email-info {
  max-width: 700px;
  display: flex;
  align-items: center;
}

.modal-email-icon {
  min-width: 50px;
  font-size: 40px;
  margin-right: 10px;
  color: var(--secondary-color);
}

.modal-markets-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.modal-markets-list li {
  list-style: none;
  margin: 10px;
  min-width: 130px;
  font-weight: 600;
}

.modal-markets-list li span {
  cursor: pointer;
}

.modal-markets-list li span:hover {
  color: var(--layout-color);
  transition: color 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .modal-container-confirm {
    height: 290px;
  }

  .modal-confirm-message {
    margin: 25px 20px;
  }
}

@media (min-width: 1024px) {
  .modal-markets-list {
    margin-left: 50px;
  }
}
