.register-container {
  margin-top: 69px;
}

.register-input-loader {
  width: 240px;
  height: 32px;
}

.register-input {
  padding: 5px 10px;
  width: 240px;
  border: 2px solid var(--disable-color);
  font-size: 15px;
  background-color: var(--ground_color);
  height: 32px;
}

.register-input:focus {
  outline: none;
  border: 2px solid var(--primary-color);
}

.register-input-error {
  border: 1px solid var(--error-color);
}

.register-input-error:focus {
  border: 1px solid var(--error-color);
}

.register-error-message {
  margin-top: 3px;
  font-size: 12px;
  color: var(--error-color);
  max-width: 230px;
}

.register-steps-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
  color: var(--layout-color);
  margin: 0 10px;
}

.register-header-icon {
  font-size: 20px;
  color: var(--layout-color);
  margin-left: 10px;
}

.register-steps-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--light-ground);
  padding: 40px 0;
}

.register-list {
  display: flex;
  align-items: center;
  height: 120px;
}

.register-list-block {
  position: relative;
  width: 2px;
  height: 100%;
  background-color: var(--disable-color);
}

.register-list-indicator {
  position: absolute;
  top: 0;
  left: -1px;
  background-color: var(--layout-color);
  width: 4px;
  height: 33%;
  transition: transform 0.3s ease-in-out;
}

.register-step1 {
  transform: translateY(0%);
}
.register-step2 {
  transform: translateY(100%);
}
.register-step3 {
  transform: translateY(200%);
}

.register-list-box {
  margin-left: 20px;
  list-style: none;
  width: 220px;
  height: 100%;
  font-size: 18px;
}

.register-list-row {
  display: flex;
  align-items: center;
  height: 33%;
  cursor: pointer;
}

.register-list-row-active {
  color: var(--headers-color);
  font-size: 20px;
  font-weight: 600;
}

.register-form {
  background-color: var(--ground_color);
  padding: 20px;
  height: fit-content;
  margin-top: 20px;
}

.register-form-box {
  display: none;
}

.register-active-form {
  display: block;
}

.register-form-body {
  display: grid;
  margin-top: 15px;
}

.register-form-product {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 2px 4px var(--disable-color);
  margin-bottom: 15px;
}

.register-form-row {
  display: grid;
  margin-bottom: 10px;
  list-style: none;
  min-height: 50px;
}

.register-form-row label {
  display: flex;
  margin-bottom: 5px;
  font-size: 14px;
}

.register-form-modal {
  margin-left: 10px;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}

.register-form-row-product {
  display: grid;
  list-style: none;
  min-height: 50px;
  margin-bottom: 10px;
}

.register-form-row-product label {
  margin-bottom: 5px;
  font-size: 14px;
}

.register-form-open {
  display: flex;
  align-self: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding-top: 10px;
  max-width: 230px;
}
.register-form-open-icon {
  margin-left: 7px;
}

.register-form-address {
  margin-bottom: 10px;
}
.register-form-address-row {
  justify-content: end;
}

.register-form-remove {
  display: flex;
  width: 50px;
  justify-content: center;
}

.register-form-remove-icon {
  color: var(--error-color);
  font-size: 20px;
  cursor: pointer;
}

.register-form-add {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 5px;
  width: fit-content;
  cursor: pointer;
}

.register-form-add:hover {
  color: var(--layout-color);
}

.register-form-add-icon {
  font-size: 20px;
  margin-right: 10px;
}

.register-form-btns {
  display: flex;
  margin-top: 20px;
}

.register-btn {
  padding: 10px 15px;
  background-color: var(--secondary-color);
  box-shadow: 2px 2px 4px var(--disable-color);
  border: none;
  color: var(--ground_color);
  font-weight: 600;
  cursor: pointer;
  margin-left: auto;
}

.register-btn:hover {
  background-color: var(--secondary-hover);
  box-shadow: 3px 3px 6px var(--disable-color);
}

.register-btn:disabled {
  background-color: var(--disable-color);
  cursor: not-allowed;
}

.register-back-btn {
  background-color: var(--disable-color);
  margin-left: 0;
}

.register-back-btn:hover {
  background-color: var(--disable-hover);
}

.register-checkbox {
  margin-right: 10px;
}

.register-small-link {
  text-decoration: underline;
  color: var(--primary-color);
}

.register-form-row-text {
  width: 230px;
  margin-top: 5px;
  font-size: 12px;
}

@media (min-width: 768px) {
  .register-form-category {
    margin-right: 50px;
  }

  .register-form-row-product {
    margin-bottom: 0;
  }

  .register-form {
    margin-top: 0px;
  }
}

@media (min-width: 1024px) {
  .register-steps-header {
    height: 80px;
    padding-top: 30px;
    padding-left: 100px;
    padding-bottom: 30px;
    font-size: 35px;
    color: var(--layout-color);
    justify-content: flex-start;
  }

  .register-header-icon {
    font-size: 35px;
  }

  .register-steps-box {
    min-height: 700px;
  }

  .register-form {
    min-width: 640px;
  }

  .register-form-body {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
  }

  .register-form-box-body {
    min-height: 210px;
  }

  .register-form-address {
    margin-right: 35px;
  }
}
