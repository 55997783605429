.case-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-ground);
  padding: 40px 0;
}

.case-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--ground_color);
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
}

.case-box-header {
  color: var(--headers-color);
  font-size: 20px;
  margin-bottom: 20px;
}

.case-box-options-list {
  display: grid;
  width: 300px;
  grid-template-columns: 1fr 1fr;
}

.case-box-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
}

.case-box-option:hover {
  color: var(--layout-color);
}

.case-check-container {
  width: 25px;
}

.case-box-label {
  margin-bottom: 5px;
  font-size: 14px;
}

.case-box-email {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.case-email-input {
  display: flex;
  flex-direction: column;
}

.case-email-btn {
  margin-top: 17px;
  margin-left: 40px;
}

.case-register-btn {
  margin-top: 40px;
}

.case-email-message {
  margin-top: 3px;
  font-size: 12px;
  color: var(--secondary-color);
  width: 250px;
  height: 15px;
}

.case-items {
  display: grid;
  max-height: 400px;
  overflow-y: auto;
  margin: 0 10px;
}

.case-items-row {
  display: flex;
  align-items: center;
  background-color: var(--ground_color);
  padding: 15px;
  cursor: pointer;
  border: 2px solid var(--ground_color);
  min-height: 80px;
  margin-bottom: 7px;
}

.case-items-row-selected {
  border: 2px solid var(--secondary-color);
}

.case-items-row-options {
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  margin-left: 10px;
}

.case-items-row-option {
  margin: 5px;
}

.case-items-row-option-header {
  font-weight: 600;
  margin-right: 7px;
}

.case-form-btns {
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.case-form-btns-error {
  color: var(--error-color);
  font-size: 14px;
  margin-right: 30px;
}

.case-steps-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--light-ground);
}

.case-issue-list {
  margin-top: 20px;
}

/* Text Editor */
.text-editor-container {
  display: flex;
  flex-direction: column;
}

.text-editor-box {
  height: 250px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid var(--disable-color);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.text-editor-box:focus {
  outline: none;
}

.text-editor-error {
  border: 1px solid var(--error-color);
}

.text-editor-toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px solid var(--disable-color);
  border-left: 1px solid var(--disable-color);
  border-right: 1px solid var(--disable-color);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px;
}

.text-editor-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  font-weight: 600;
  font-size: 20px;
  background-color: var(--light-ground);
  box-shadow: 1px 1px 2px var(--disable-color);
  border-radius: 5px;
  border: none;
  margin: 5px;
  cursor: pointer;
}

.text-editor-btn:hover {
  background-color: rgb(240, 240, 240);
  box-shadow: 2px 2px 4px var(--disable-color);
}

.text-editor-btn-active {
  color: var(--btn-blue);
}

.text-editor-devider {
  margin: 0 10px;
  height: 35px;
  width: 2px;
  background-color: var(--disable-color);
}

.text-editor-box li {
  margin-left: 20px;
}

/* Files Attachment */
.case-addfile-container {
  display: flex;
  flex-direction: column;
}

.case-addfile-main {
  height: 40px;
}

.case-addfile-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--disable-color);
  padding: 10px 30px;
  font-size: 14px;
  text-align: center;
}

.case-addfile-box-error {
  border: 1px dashed var(--error-color);
}

.case-attached-box {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-top: 10px;
}

.case-attached-file {
  display: flex;
  align-items: center;
  font-size: 11px;
  background-color: var(--axis-background);
  padding: 3px 7px;
  border-radius: 5px;
  margin: 5px 0;
  box-shadow: 1px 1px 2px var(--disable-color);
  width: fit-content;
}

.case-attached-delete {
  font-size: 18px;
  color: var(--error-color);
  cursor: pointer;
  margin-left: 5px;
}

.case-attachments-row {
  margin-top: 10px;
  align-self: flex-start;
}

.case-upload-button {
  color: rgba(0, 87, 248, 0.5);
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 0 !important;
}

.case-upload-button:hover {
  color: rgba(58, 125, 250, 0.5);
}

.dragable-box {
  border: 2px dashed var(--secondary-color) !important;
  box-shadow: 2px 2px 4px var(--secondary-color);
}

/* List switcher */
.case-list {
  display: flex;
  align-items: center;
  height: 199px;
}

.case-list-block {
  position: relative;
  width: 2px;
  height: 100%;
  background-color: var(--disable-color);
}

.case-list-indicator {
  position: absolute;
  top: 0;
  left: -1px;
  background-color: var(--layout-color);
  width: 4px;
  height: 25%;
  transition: transform 0.3s ease-in-out;
}

.case-step1 {
  transform: translateY(0%);
}
.case-step2 {
  transform: translateY(100%);
}
.case-step3 {
  transform: translateY(200%);
}
.case-step4 {
  transform: translateY(300%);
}

.case-list-box {
  margin-left: 20px;
  list-style: none;
  width: 220px;
  height: 100%;
  font-size: 18px;
}

.case-list-row {
  display: flex;
  align-items: center;
  height: 25%;
  cursor: pointer;
}

.case-list-row-active {
  color: var(--headers-color);
  font-size: 20px;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .case-box {
    width: 800px;
    height: 120px;
  }

  .case-container {
    min-height: 650px;
  }
  .case-items-row {
    width: 800px;
  }

  .case-items-row-option {
    margin: 10px;
  }
  .case-items-row-options {
    margin-left: 0;
  }

  .text-editor-container {
    width: 500px;
  }

  .case-addfile-container {
    width: 500px;
  }
}
