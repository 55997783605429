.header-logo {
  height: 30px;
  width: auto;
}

.header-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #333333;
  height: 69px;
  z-index: 29;
}

.header-menu-icon {
  color: var(--ground_color);
  font-size: 40px;
  margin-right: 25px;
}

.header-list {
  margin: 10px 30px;
  display: none;
  height: 100%;
  align-items: flex-end;
}

.header-option {
  position: relative;
  margin: 0 22px;
  list-style: none;
  padding-bottom: 25px;
  cursor: pointer;
}

.header-option-right {
  margin: 0 15px;
}

.header-option:hover .header-option-link {
  color: var(--disable-color);
  transition: color 0.2s ease-in-out;
}

.header-option-link {
  color: var(--ground_color);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.header-option-icon {
  margin-left: 5px;
}

.header-option-list {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 200px;
  min-width: 200px;
  padding: 5px;
  top: 100%;
  left: 50%;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
  transform: translateX(-50%);
  background: #fff;
  z-index: 99;
}

.header-option-list:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 6px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.header-option-list li {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.header-option-list a {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: black;
  padding: 7px 18px;
  line-height: 18px;
  text-decoration: none;
}

.header-option-list a:hover {
  background-color: #f3f3f3;
}

.header-option:hover .header-option-list {
  visibility: visible;
  opacity: 1;
}

.header-option-list-icon {
  margin-right: 7px;
  font-size: 20px;
  color: var(--headers-color);
}

.header-left-list {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}

.header-left-icon {
  color: var(--ground_color);
  font-size: 25px;
}

.header-left-icon-secondary {
  color: var(--secondary-color);
}

.header-left-icon-hide {
  display: none;
}

@media (min-width: 768px) {
  .header-left-icon-hide {
    display: block;
  }

  .header-logo {
    height: 40px;
    width: auto;
  }
}

@media (min-width: 1130px) {
  .header-list {
    display: flex;
  }
  .header-left-list {
    flex-grow: 0;
    justify-content: flex-start;
  }
}

@media (min-width: 1200px) {
  .header-list {
    margin: 10px 60px;
  }
}
